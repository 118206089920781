import React, { useState, useEffect, useRef } from "react"
import { GrayBorder } from "../../../../globalStyle"
import {
  DetailWrapper,
  DetailName,
  DetailContent,
  ContentHeight,
} from "./style"
import IDetails from "./types"

const Details = (props: IDetails) => {
  const { name, details } = props 

  return (
    <DetailWrapper>
        <DetailName>{name}</DetailName>
      <GrayBorder />
      <DetailContent >
        <ContentHeight
          dangerouslySetInnerHTML={{ __html: details }}
        ></ContentHeight>
      </DetailContent>
    </DetailWrapper>
  )
}

export default Details
