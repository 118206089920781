import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import { graphql } from "gatsby"
import Job from "../components/Sections/Job"

const JobTemplate = ({ pageContext, path }) => {
  return (
    <Layout>
      <Head
        description={pageContext.jobData.metaDescription}
        pageTitle={"Job Offer | " + pageContext.jobData.title}
        keywords={pageContext.jobData.keywords}
      />
      <Job {...pageContext} />
    </Layout>
  )
}

export default JobTemplate
