import React from "react"
import { JobWrapper, DetailsContainer, ButtonWrapper, TextWrapper } from "./style"
import { H3Title, OrangeSpan, H2Title, P, Section } from "../../../globalStyle"
import Details from "./Details"
import { IJob } from "./types"
import Button from "../../UI/Button"
import { Container } from "../../../globalStyle"

const Job = ({
  jobData: {
    description,
    details,
    methodology,
    benefits,
    isImportant,
    title,
  },
}: IJob) => {
  return (
    <Section>
      <Container>
      <JobWrapper isImportant={isImportant}>
      <H2Title style={{ marginBottom: "0px" }} marginLeft="15px" thin={true}>
          Join Oakfusion <OrangeSpan>Team</OrangeSpan>
        </H2Title>
        <TextWrapper>
          <P>
            Our mission is to create the best software in a great working
            atmosphere. Look at our job offers below.
          </P>
        </TextWrapper>
      <ButtonWrapper>
          <Button slug="https://system.erecruiter.pl/FormTemplates/RecruitmentForm.aspx?WebID=cdf7c9be45f246f0a710c7a25fff4b90">
            Apply now
          </Button>
        </ButtonWrapper>
      <H3Title isDarkBlue={true}>
        {title}
        <OrangeSpan></OrangeSpan>
      </H3Title>
      <DetailsContainer>
        <Details
          name="Brief Job Description"
          details={description.description}
        />
        <Details name="Interview" details={methodology.methodology} />
        <Details name="Offer Details" details={details.details} />
        <Details name="Benefits" details={benefits.benefits} />
      </DetailsContainer>
    </JobWrapper>
    </Container>
    </Section>
  )
}

export default Job
