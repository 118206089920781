import styled from "styled-components"

export const DetailWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

export const DetailName = styled.p`
  transition: 0.3s;
  font-size: ${props => props.theme.fontSizes.normal};
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.colors.darkBlue};
`

export const DetailContent = styled.div`
  transition: 0.3s;
  opacity: 1;
  position: relative;
  height: auto;
`
export const ContentHeight = styled.p`
  position: relative;
`
