import styled, { css } from "styled-components"
import { IJobWrapper } from "./types"

export const JobWrapper = styled.div<IJobWrapper>`
  margin: 130px 15px 60px 15px;
  display: flex;
  flex-direction: column;
  & h3 {
    ${({
      isImportant,
      theme: {
        colors: { orange },
      },
    }) =>
      isImportant !== null &&
      isImportant === true &&
      css`
        color: ${orange} !important;
      `}
  }
`

export const DetailsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`

export const ButtonWrapper = styled.div`
  margin-bottom: 50px;
`
export const TextWrapper = styled.div`
  margin-top: 0;
  padding: 0 15px 0 15px;
`